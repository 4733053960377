import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import { Modal } from 'react-bootstrap';
import { Network } from '../../component/network/network';
import { baseUrl } from '../../component/network/url';
import Navbar from 'react-bootstrap/Navbar';
import './navigationBar.css?v=2.1';
import logoDark from '../../assets/home/gardifyLogoDark.png';
import logoLight from '../../assets/gardifyLogoLight.svg';
import burgerIcon from '../../assets/burgerMenu.svg';
import crossIcon from '../../assets/crossIcon.svg';
import youtubeLogo from '../../assets/youtube-logo.png';
import instaIcon from '../../assets/instaIcon.png';
import fbIcon from '../../assets/fbIcon.png';
import pinterestIcon from '../../assets/pinterestIcon.png';

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const network = Network();
  const [email, setEmail] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (expanded) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [expanded])

  const handleSubscribe = async () => {

    if (!email || email.trim().length > 3) {
      setLoading(true);
      network.postRequest(`${baseUrl}AccountAPI/newsletter?email=${email}`)
        .then((data) => {
          setModalMessage(data ?? "An error occurred");
          setLoading(false);
          setShowModal(true);
          setEmail('');

        })
        .catch((error) => {
          setLoading(false);
          setModalMessage(error || 'Fehler');
        });

    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (<>
    <Navbar expanded={expanded} className="custom-navbar shadow" expand="lg" fixed="top">
      <>
        <Navbar.Brand className={`${expanded ? 'isOpen' : ''}`}>
          <a href="/">
            <img src={logoLight} alt="logo light" height={40} className="logo-light" />
            <img src={logoDark} alt="logo dark" height={50} className="logo-dark"
            />
          </a>
        </Navbar.Brand>
        <Nav className="Kostenlos-starten-container" >
          <Nav.Link className={`Kostenlos-starten ${expanded ? 'isOpen' : ''}`}
            href="/Anmeldung"
          >
            Kostenlos starten
          </Nav.Link>
        </Nav>
        <Navbar.Toggle
          style={{ paddingRight: '0' }}
          aria-controls="basic-navbar-nav"
          onClick={handleToggle}
          className="custom-toggler"
        >
          <img
            src={expanded ? crossIcon : burgerIcon}
            alt="menu icon"
          />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center nav-menu-container">
          <div className="nav-menu-divider"></div>
          <Nav className="mx-auto navbar-items">
            <Nav.Link href="/search/plants" className="custom-nav-item">Pflanzensuche</Nav.Link>
            <Nav.Link href="/scan" className="custom-nav-item">Pflanzenscan</Nav.Link>
            <Nav.Link href="/Pflanzen-Doc" className="custom-nav-item">Pflanzen-Doc</Nav.Link>
            <Nav.Link href="/uns" className="custom-nav-item">Über uns</Nav.Link>
            <Nav.Link href="/inspiration" className="custom-nav-item">Gardify News</Nav.Link>
            <Nav.Link href="https://app.gardify.de/#/auth/login" className="custom-nav-item login-button">Login</Nav.Link>
          </Nav>
          {/* <Nav className="ms-auto">
            <Nav.Link
              href="https://app.gardify.de/#/auth/start"
              className="login-button"
              style={{ color: "#7A9E33" }}
            >
              Login
            </Nav.Link>
          </Nav> */}


          <div className="d-block d-lg-none d-xl-none d-xl-none mt-4 mb-5 pb-5">

            <div className="mb-4">
              <Nav >
                <Nav.Link className="pb-0" href="mailto:support@gardify.de?subject=Kontakt" rel="nofollow">Kontakt</Nav.Link>
                <Nav.Link className="pb-0" href="/agb" >AGB</Nav.Link>
                <Nav.Link className="pb-0" href="/impressum" >Impressum</Nav.Link>
                <Nav.Link href="/datenschutz" >Datenschutz</Nav.Link>
              </Nav>
            </div>

            <div className="d-flex mb-5">
              <a className="pb-0 me-4" href="https://www.youtube.com/c/gardify" target="_blank" rel="noopener noreferrer" >
                <img src={youtubeLogo} height={34} />
              </a>
              <a className="pb-0 me-4" href="https://www.instagram.com/gardify_official" target="_blank" rel="noopener noreferrer" ><img src={instaIcon} height={34} /></a>
              <a className="pb-0 me-4" href="https://www.youtube.com/c/gardify" target="_blank" rel="noopener noreferrer"><img src={fbIcon} height={34} /></a>
              <a href="https://www.pinterest.de/gardify_official" target="_blank" rel="noopener noreferrer"><img src={pinterestIcon} height={34} /></a>
            </div>
            <span className='fw-bold'>Newsletter bestellen</span>
            <>
              <div className="newsletter-input-container mb-5">

                <input
                  type="email"
                  placeholder="E-Mail-Adresse"
                  className="newsletter-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="button" onClick={() => handleSubscribe()} className="newsletter-button">{loading &&
                  <span className="bubble-spinner"></span>}
                  Los</button>
              </div>
            </>

          </div>
        </Navbar.Collapse>

      </>

    </Navbar>
    <Modal show={showModal} onHide={handleClose} centered bottom style={{ zIndex: '999999999999999999999' }}>
      <Modal.Body className='d-flex justify-content-between' style={{ backgroundColor: modalMessage === 'Fehler' ? '#ffcccc' : '#e7f6ec' }}>
        {modalMessage}
        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
      </Modal.Body>
    </Modal>
  </>

  );
};

export default NavigationBar;
