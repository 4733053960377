export const localUrl = "https://localhost:44328/api/v2/";
export const onlineUrl = "https://newserver_gardify.sslbeta.de/api/v2/";
export const baseUrl = onlineUrl;

export const baseImageUrl = "https://gardify.de/intern/";
export const plantDocImageUrl = "https://gardifybackend2.sslbeta.de/"
export const placeholderImage = "https://gardify.de/assets/images/placeholder/plant_placeholder.png";

export const appStoreUrl = "https://apps.apple.com/de/app/gardify-deine-garten-app/id1525529405";
export const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.gardify.android";
 
 
