import React from "react";
import "./layout.css?v=2.1";

const Layout = ({ children }) => {
    return (
        <div className="layout">

                {children}

        </div>
    );
};

export default Layout;
